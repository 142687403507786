import { styled } from 'baseui';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import * as React from 'react';

const Image = styled(Img, {
  objectFit: 'cover',
  objectPosition: '100% 0',
  width: '100%',
  height: '100%',
  maxHeight: '25rem',
});

const nodeURL = 'https://www.instagram.com/p';

const getUrl = (id, caption) => {
  const cap = caption.split("\n")[0].toLowerCase().trim();
  const keys = cap.split(' ');
  switch (keys[0]) {
    case 'benyar':
      return `https://www.bestbenyarwatches.com/${keys[1].split('-')[1]}`
    case 'curren':
      return `https://www.bestcurrenwatches.com/${keys[1]}`
    case 'megir':
      return `https://www.bestmegirwatches.com/${keys[1]}`
    case 'naviforce':
    case 'armiforce':
      return `https://www.bestnaviforcewatches.com/${keys[1]}`
    case 'cadisen':
      return `https://www.cadisenwatch.com/${keys[0]}-${keys[1]}?aff=3`
    case 'pagani':
      return `https://www.paganidesignwatch.com/${keys[0]}-${keys[1]}-${keys[2]}?aff=3`
    case 'starking':
      return `https://www.starkingwatches.com/${keys[0]}-${keys[1]}?aff=3`
    case 'steeldive':
      return `https://www.steeldivewatch.net/${keys[0]}-${keys[1]}?aff=3`
    default:
      return `${nodeURL}/${id}`;
  }
}

const ImageGallery = () => {
  const {
    allInstaNode: { edges },
  } = useStaticQuery(graphql`
    {
      allInstaNode(sort: { fields: timestamp, order: DESC }, limit: 12) {
        edges {
          node {
            id
            caption
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);

  const renderImages = () => {
    const images = edges.map(({ node }) => {
      const {
        id,
        caption,
        localFile: { childImageSharp },
      } = node;
      return (
        <FlexGridItem key={id} flexGridItemIndex={id}>
          <a href={getUrl(id, caption)} target="_blank" >
            <Image
              loading="lazy"
              alt={caption.split("\n")[0] || ''}
              fluid={childImageSharp.fluid}
            />
          </a>
        </FlexGridItem>
      );
    });

    return images;
  };

  return (
    <FlexGrid
      // Brackets specify the options for different breakpoints
      // 1 column for small devices
      // 2 columns for medium devices
      // 3 columns for large devices
      flexGridColumnCount={[1, 2, 3]}
      flexGridColumnGap={['scale0', 'scale200']}
      flexGridRowGap={['scale0', 'scale200']}
    >
      {renderImages()}
    </FlexGrid>
  );
};

export default ImageGallery;
